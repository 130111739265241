import axios from 'axios';
const TOKEN = 'Bearer 26d460b39301781b10d37af31e2e5a4a';

import { setCustomers } from "./localbase";

export default {

    async getClienti() {
        const data = new FormData();
        //data.append("limit", "300");
        data.append("offset", "0");
        data.append("orderby", "customers_company");
        data.append("orderdir", "asc");

        const response = await axios.post('/search/customers', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //qui salvo in indexeddb la collection Clienti nel db Clienti
        // con chiave id cliente e valore il cliente
        response.data.data.forEach(cliente => {
            setCustomers(cliente);
        });
        //setCustomers(response.data.data);
        //localStorage.setItem('clienti', JSON.stringify(response.data.data));
        //console.log(response);
        return response.data.data;
    },

    async getClienteDetail(id: string) {
        const data = new FormData();
        data.append("where[customers_id]", id.toString());

        const response = await axios.post('/search/customers', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    async getClienteSedi(id: string) {
        const data = new FormData();
        data.append("orderby", "customers_company");
        data.append("orderdir", "asc");
        data.append("where[customers_shipping_address_customer_id]", id.toString());

        const response = await axios.post('/search/customers_shipping_address', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    }
}