import axios from 'axios';
const TOKEN = 'Bearer 26d460b39301781b10d37af31e2e5a4a';

export default {

    async getSedeReparti(sede_id: string, customer_id: string) {
        const data = new FormData();
        data.append("where[reparto_sede_operativa]", sede_id.toString());
        data.append("where[reparto_customer_id]", customer_id.toString());


        const response = await axios.post('/search/reparto', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    }
}